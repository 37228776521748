import React, { useState, useEffect } from "react";
import ApiEngine from "../../../util/ApiEngine";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ApiKey, ApiUrl, WebUrl } from "../../../util/Constant";
import { Link, Redirect, useHistory } from "react-router-dom";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import { stringIsNullOrEmpty, createFormBody } from "../../../util/Util";
import {
	showResponseMessage,
	showResponseMessageWithoutT,
	setPaymentList,
} from "../../../redux/AppAction";
import { find, isEmpty, map } from "lodash";
import SweetAlert from "react-bootstrap-sweetalert";
import Spinner from "react-bootstrap/Spinner";
import { themeIcons } from "config/theme";
import VIcon from "components/custom/Icon";
import UploadReloadReceipt from "../transaction/UploadReloadReceipt";
import { heartBeat } from "../../../redux/AuthAction";
import InstantPayDeposit from "./InstantPayDeposit";

// function CustomToggle({ children, eventKey }) {
// 	const wrapOnClick = useAccordionButton(eventKey, () =>
// 		console.log("totally custom!")
// 	);

// 	return <div onClick={wrapOnClick}>{children}</div>;
// }

/// <summary>
/// Author: -
/// </summary>
const Deposit = (props) => {
	const { t, i18n } = useTranslation();
	const _history = useHistory();
	const _dispatch = useDispatch();
	const { handleSubmit, control, setValue } = useForm();
	
	const _NORMAL_DEPOSIT = -1;
	const _INSTANT_PAYMENT = 1;
	const [instantPayOption, setInstantPayOption] = useState([]);
	const [tempInstantPayOption, setTempInstantPayOption] = useState([]);
	const [paymentOptionList, setPaymentOptionList] = useState(
		useSelector((state) => state.appState.payments)
	);
	const [paymentOption, setPaymentOption] = useState(_NORMAL_DEPOSIT);
	const [activeTab, setActiveTab] = useState(_INSTANT_PAYMENT);
	const [showFullnamePopup, setShowFullnamePopup] = useState(false);
	const [memberId, setMemberId] = useState("");
	const [activePaymentOption, setActivePaymentOption] = useState("");
	
	const [headerMsg, setHeaderMsg] = useState("");
	const [countdownPeriod, setCountdownPeriod] = useState(0);
	const [hasPendingTxn, setHasPendingTxn] = useState(false);
	const [countdownStr, setCountdownStr] = useState("");
	const [timeDiffSeconds, setTimeDiffSeconds] = useState(0);
	const [transType, setTransType] = useState("");
	const [countdownTime, setCountdownTime] = useState();
	const _SECONDS_IN_MS = 1000;
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [loadingSpinner, setLoadingSpinner] = useState(false);
	const [selected, setSelected] = useState("");
	const [showBankOptions, setShowBankOptions] = useState(true);
	const [showBackward, setShowBackward] = useState(false)

	useEffect(() => {
		init();
	}, []);

	// useEffect(() => {
	// 	if (countdownPeriod > 0) {
	// 		clearTimeout(countdownTime);
	// 		setCountdownTime(
	// 			setTimeout(() => {
	// 				let tempPeriod = countdownPeriod - 1;
	// 				setCountdownPeriod(tempPeriod);
	// 				let tempX = moment.duration(tempPeriod);
	// 				let tempMM = Math.floor(tempX["_milliseconds"] / 60);
	// 				let tempSS = tempX["_milliseconds"] - tempMM * 60;
	// 				setTimeDiffSeconds(tempX["_milliseconds"]);
	// 				let tempStr =
	// 					(tempMM.toString().length > 1 ? tempMM : "0" + tempMM) +
	// 					" " +
	// 					t("MINUTES") +
	// 					" " +
	// 					(tempSS.toString().length > 1 ? tempSS : "0" + tempSS) +
	// 					" " +
	// 					t("SECOND");
	// 				setCountdownStr(tempStr);
	// 			}, _SECONDS_IN_MS)
	// 		);
	// 	}
	// }, [countdownPeriod]);

	useEffect(()=>{

		if(paymentOptionList.length > 0){
			const isOnlyOneNormal  = paymentOptionList.filter(item => item.value === 'Normal') && paymentOptionList.length == 1;

			if(isOnlyOneNormal){
				setSelected("Normal");
				setShowBackward(false)

			}else{
				setSelected("");
				setShowBackward(true)

			}

		}
	},[paymentOptionList])

	async function init() {
		var oktocont = true;
		setLoadingSpinner(true);
		_dispatch(heartBeat());
		(async function () {
			//KT: NO POINT HAVING THIS, WE ARE NOT REQUIRING FULL NAME TO DEPOSIT
			/*let memberJson = await ApiEngine.get(ApiUrl._API_GET_USER_FULLNAME);
			if (memberJson[ApiKey._API_SUCCESS_KEY]) {
				// setMemberId(memberJson[ApiKey._API_DATA_KEY]["memberId"]);
				if (
					stringIsNullOrEmpty(memberJson[ApiKey._API_DATA_KEY]["userFullName"])
				) {
					setShowFullnamePopup(true);
				}
			}*/

			let tempOptions = [];

			tempOptions.push({
				key: _NORMAL_DEPOSIT,
				value: _NORMAL_DEPOSIT,
				label: "Normal",
				image: null,
				paymentType: t("NORMAL_DEPOSIT"),
				fee: 0,
				remark: t("NORMAL_BANK_TRANSFER"),
			});


			//payment gateway list
			let responseJson = await ApiEngine.get(
				ApiUrl._API_GET_USER_PAYMENT_GATEWAY_INFO
			);
			if (responseJson[ApiKey._API_SUCCESS_KEY]) {
				setInstantPayOption(responseJson[ApiKey._API_DATA_KEY]);
				let temp = responseJson[ApiKey._API_DATA_KEY].findIndex(
					(x) => x.instantPayGatewayString === activePaymentOption
				);

				setPaymentOption(temp);
				responseJson[ApiKey._API_DATA_KEY].map((option, index) => {
					option["status"] === true &&
						tempOptions.push({
							key: index,
							value: option.id,
							label: option.instantPayGatewayString,
							image: option.image,
							fee: option.serviceCharge,
							paymentType:
								option.instantPayGatewayString.endsWith(" QR") ||
								option.instantPayGatewayString == "TruePay"
									? t("DUIT_NOW")
									: t("INSTANT_PAYMENT"),
							remark: option.remarks,
							notes:option.notes
						});
				});
				setTempInstantPayOption(tempOptions);

				if (
					tempOptions.filter((x) => x.paymentType === t("INSTANT_PAYMENT"))
						.length <= 0
				) {
					setActiveTab(_NORMAL_DEPOSIT);
				}
				setValue("paymentMethod", tempOptions[0].value);
				setPaymentOptionList(tempOptions);

				_dispatch(setPaymentList(tempOptions));
				// setSelected("Normal");

			}
			//-------------------------------------------------------------------


			// setHeaderMsg("");
			// var pendingTransactionJson = await ApiEngine.get(
			// 	ApiUrl._API_GET_PENDING_TRANSACTION
			// );
			// if (pendingTransactionJson[ApiKey._API_SUCCESS_KEY]) {
			// 	if (pendingTransactionJson[ApiKey._API_DATA_KEY] != null) {
			// 		clearTimeout(countdownTime);
			// 		let tempS = parseInt(
			// 			pendingTransactionJson[ApiKey._API_DATA_KEY]["timeSpan"]
			// 		);
			// 		setTransType(
			// 			pendingTransactionJson[ApiKey._API_DATA_KEY]["transType"]
			// 		);
			// 		setTimeDiffSeconds(tempS);
			// 		setCountdownPeriod(tempS < 0 ? 0 : tempS);
			// 		setHasPendingTxn(true);
			// 		oktocont = false;
			// 		setHeaderMsg(t("NEW_TXN_PROHIBITED"));
			// 	}
			// } else {
			// 	_dispatch(
			// 		showResponseMessage(
			// 			false,
			// 			pendingTransactionJson[ApiKey._API_MESSAGE_KEY]
			// 		)
			// 	);
			// }

			//KT: IF PENDING TXN FOUND, WHY CONTINUE AND STILL GET DATA WE DONT NEED?
			if (oktocont) {
				await getRestore();
				//await getMultipleTap();
			}

			//KT this is very slow, we're not going to use Payment Gateway for AUD, might as well dont check and waste time
			// setSelected("Normal");
			setLoadingSpinner(false);
		})();
	}

	async function getRestore() {
		let responseJson = await ApiEngine.get(ApiUrl._API_RESTORE_ALL_WALLET);
	}


	return (
		<div>
			{showBankOptions && selected === "" && (
				<>
					{showConfirmation && (
						<SweetAlert
							warning
							confirmBtnText={t("CONFIRM")}
							confirmBtnBsStyle="danger"
							onConfirm={() => {
								setShowConfirmation(false);
							}}
							style={{
								backgroundColor: "#05102F",
								borderColor: "white !important",
								opacity: 0.9,
								color: "white",
								marginTop: "68%",
							}}
							cancelBtnBsStyle="#45ff8b"
						>
							{t(
								"PLEASE_WAIT_FOR_PREVIOUS_TRANSACTION_APPROVAL_OR_CONTACT_CUSTOMER_SERVICE"
							)}
						</SweetAlert>
					)}

					{!loadingSpinner && paymentOptionList.length > 1 && <div className="">
						<div className="first">{t("SELECT_A_RELOAD_OPTION")}</div>
						<div className="second">
							<div></div>
						</div>
					</div>}

					{
						<div className="t3-settings-menu-list mt-3">
							{loadingSpinner ? (
								<div
									style={{
										textAlign: "center",
										marginTop: "25px",
										marginBottom: "20px",
									}}
								>
									<Spinner
										animation="border"
										role="status"
										size="sm"
										style={{
											verticalAlign: "middle",
											width: "2rem",
											height: "2rem",
										}}
									>
										<span className="sr-only">{t("LOADING")}...</span>
									</Spinner>
								</div>
							) : (
								paymentOptionList.map((option, index) => {
									return (
										<div
											className="t3-settings-menu-list-item"
											key={index}
											onClick={() => {
												// if (hasPendingTxn) {
												// 	setShowConfirmation(true);
												// } else {
												// 	setSelected(option["label"]);
												// 	setShowBankOptions(false);
												// }
												setSelected(option["label"]);
													setShowBankOptions(false);
											}}
											style={{ cursor: "pointer" }}
										>
											<div className="first">
												<div>
													<VIcon
														icon={option["label"] !== "Normal" ? themeIcons.wallet : themeIcons.settingsBank}
														className="theme-icon-size-20 vicon"
													/>
												</div>
												<div>
													<div>
														<div className="mb-0">
															{option["label"] == "Normal"
																? t("BANK")
																: option["label"]}
														</div>
														<span className="font12">{option["remark"]}</span>
													</div>
												</div>
											</div>
											<div className="second">
												<VIcon icon={themeIcons.angleRight} className="vicon" />
											</div>
										</div>
									);
								})
							)}
						</div>
					}

				</>
			)}



			{/* {hasPendingTxn && (
				<div className="mt-3 mb-3 reminder-red">
					<div className="first">
						<div className="first">
							<img
								src={require("../../../assets/img/new_icons/red.png")}
								className="img-responsive"
								alt="icon"
							/>
						</div>
						<div>
							<p className="font14 mb-0 f-weight-500">
								{timeDiffSeconds > 0
									? t("PLEASE_WAIT") +
									" " +
									countdownStr +
									" " +
									(transType === "Deposit"
										? t("FOR_DEPOSIT_APPROVAL")
										: t("FOR_WITHDRAWAL_APPROVAL"))
									: headerMsg}
							</p>
						</div>
					</div>
				</div>
			)} */}


			{!showBankOptions && showBackward && (
				<div className="d-flex align-items-center mb-3">
					<div
						className=""
						onClick={() => {
							setSelected("");
							setShowBankOptions(true);
						}}
					>
						<VIcon
							icon={themeIcons.angleLeft}
							className="vicon theme-icon-size-20"
						/>
					</div>
					<h5 className="mb-0 ml-3">{t(`${selected}`)}</h5>
				</div>
			)}
			

			{ selected === "Normal" ? (
				<>
					<UploadReloadReceipt />
				</>
			) : selected === "" ? (
				""
			) : (
				<>
					
					{/* <InstantPayDeposit
						instantBankSetting={
							instantPayOption.filter(
								(x) => x.instantPayGatewayString === selected
							)[0]
						}


					/> */}
					<InstantPayDeposit
						instantBankSettingId={
							instantPayOption.filter(
								(x) => x.instantPayGatewayString === selected
							)[0].id
						}
						bankId={
							instantPayOption.filter(
								(x) => x.instantPayGatewayString === selected
							)[0].instantBankSettingModel[0].bankId
						}
						instantBankSetting={find(instantPayOption, {instantPayGatewayString : selected})}
						instantPayGatewayId={
							instantPayOption.filter(
								(x) => x.instantPayGatewayString === selected
							)[0].instantPayGatewayId
						}
						 instantPayGatewayNotes={
							instantPayOption.filter(
								(x) => x.instantPayGatewayString === selected
							)[0].notes
						 }
						
					/>
					    {/* <InstantPayDeposit
                        gatewayOption={instantPayOption[paymentOption]}
                        parentInit={init}
                      /> */}
				</>
			)}
		</div>
	);
};

export default Deposit;
