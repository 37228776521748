import { themeIcons } from "config/theme";
import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import i18n from "localization/i18n";
import {
  setRegisterModal,
} from "../../redux/AppAction.js";
import { useTranslation } from "react-i18next";
import { Alert, Modal, ModalBody, Spinner } from "reactstrap";
import Input from "components/custom/Input";
import VIcon from "components/custom/Icon";
import { useDispatch, useSelector } from "react-redux";
import { setLoginModal, showMessage, setBusy, setIdle, bindCompanyIcon } from "redux/AppAction";
import { useForm } from "react-hook-form";
import { performLoginReturnMessage } from "redux/AuthAction";
import SecureLS from "secure-ls";
import { ApiKey, ApiUrl, SessionKey } from "util/Constant";
import { createFormBody, createMultiPartFormBody } from "../../util/Util";
import ApiEngine from "util/ApiEngine";
import { map, debounce, find, filter, upperCase } from "lodash";
import PinInput from "react-pin-input";

const LoginModal = (props) => {
  var { t } = useTranslation();
  const isDepositModal = useSelector((state) => state.appState.depositModal);
  const depositAndWithdrawActive = useSelector(
    (state) => state.appState.depositTab
  );
  const loginModal = useSelector((state)=>state.appState.loginModal)
  var _dispatch = useDispatch();
  var ls = new SecureLS();
  const {
    register,
    handleSubmit,
    errors,
    clearErrors,
    setError,
    setValue,
    getValues,
    watch,
    control,
    trigger,
    phoneNumberChk,
  } = useForm({
    mode: "onBlur",
  });
  const { handleSubmit: PasswordUpdatedHandleSubmit } = useForm();

  const [showModalLogin, setShowModalLogin] = useState(true);
  const [hideLoginPassword, setHideLoginPassword] = useState(true);

  const [visibleLoginAlert, setVisibleLoginAlert] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const [activeLanguage, setActiveLanguage] = useState(
    localStorage.getItem(SessionKey._LANGUAGE) || "EN"
  );
  const [loginMessage, setLoginMessage] = useState("");
  const [isForgotPasswordLoading, setIsForgotPasswordLoading] = useState(false);
  const [countdownTAC, setCountdownTAC] = useState(false);
  const [canSendTAC, setCanSendTAC] = useState(true);
  const [TACcountdownPeriod, setTACCountdownPeriod] = useState(0);
  const [verifyPhoneTAC, setVerifyPhoneTAC] = useState(false);
  const [countdownPeriod, setCountdownPeriod] = useState(0);
  const [username, setUsername] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [tempPhoneNum, setTempPhoneNum] = useState("");
  const [isTacModal, setIsTacModal] = useState(false);
  const [isForgetPassword, setIsForgetPassword] = useState(false);
  const [attempt, setAttempt] = useState(1);
  const [FPButtonAttempt, setFPButtonAttempt] = useState(0);
  const [TACsent, setTACsent] = useState(true);
  const [TACtimedOut, setTACtimedOut] = useState(true);
  const [isUpdatePassword, setIsUpdatePassword] = useState(false);
  const [isPasswordUpdated, setIsPasswordUpdated] = useState(false);
  const [isTACLoading, setIsTACLoading] = useState(false);
  const [isResetPasswordLoading, setIsResetPasswordLoading] = useState(false);

  const [isLiveChatOpen, setIsLiveChatOpen] = useState(false);
  const [emailExist, setEmailExist] = useState(false);

  const [TACtimer, setTACtimer] = useState(setTimeout(() => {}, 60000));
  const [timerPeriod, setTimerPeriod] = useState(null);
  const _TAC_VALID_TIMER = 60; // in seconds
  const _SECONDS_IN_MS = 1000;
  const _MIN_PASSWORD_LENGTH = 9;
  const [tacCode, setTacCode] = useState(""); 
  const companyIcons = useSelector((state) => state.appState.companyIcons);
	const [logo, setLogo] = useState();
  const [alertIcon, setAlertIcon] = useState();
  const [loginBanner, setLoginBanner] = useState();

  const elePin = useRef(null);
  const [_email, _setEmail] = useState("");
  const [_emailText, _setEmailText] = useState("");
  const [pinInputDisabled, setPinInputDisabled] = useState(false);
  const [tempPinNumber, setTempPinNumber] = useState();
  const OTP_VIA_SMS = 1;
	const OTP_VIA_EMAIL = 2;
	const OTP_VIA_WHATSAPP = 3;
  const [pinLoading, setPinLoading] = useState(false);
  const customerServiceUrl = "/livechat";

  const verifyState = {
    button: 1,
  };
  const {
    register: loginRegister,
    handleSubmit: loginHandleSubmit,
    errors: loginErrors,
  } = useForm();

  const {
    register: forgotPasswordRegister,
    handleSubmit: forgotPasswordHandleSubmit,
    errors: forgotPasswordErrors,
    watch: forgotPasswordWatch,
    setValue: forgotPasswordSetValue,
    unregister: forgotPasswordUnregister,
  } = useForm();

  const {
    register: tacVerifyRegister,
    handleSubmit: tacVerifyHandleSubmit,
    errors: tacVerifyErrors,
    watch: tacVerifyWatch,
    setValue: tacVerifySetValue,
    unregister: tacVerifyUnregister,
  } = useForm();

  // Register the PinInput
  useEffect(() => {
    tacVerifyRegister('taccode');
  }, [tacVerifyRegister]);

  const {
    register: ResetPasswordRegister,
    handleSubmit: ResetPasswordHandleSubmit,
    errors: ResetPasswordErrors,
    watch: ResetPasswordWatch,
    setValue: ResetPasswordSetValue,
    unregister: ResetPasswordUnregister,
  } = useForm();

  useEffect(() => {
		//bindCompanyIcon(_dispatch);
	}, []);

	useEffect(() => {
		if (companyIcons.length > 0) {
			getCompanyIcon();
		}
	}, [companyIcons]);

  useEffect(() => {
    if (countdownPeriod > 0) {
      setTimerPeriod(
        setTimeout(() => {
          let tempPeriod = countdownPeriod - 1;
          setCountdownPeriod(tempPeriod);
        }, _SECONDS_IN_MS)
      );
    } else if (countdownPeriod == 0) {
      // console.log("why calling this?");
      //checkMemberEmail();
    }
  }, [countdownPeriod]);

  useEffect(() => {
    if (TACcountdownPeriod > 0) {
      setTACtimer(
        setTimeout(() => {
          let tempPeriod = TACcountdownPeriod - 1;
          setTACCountdownPeriod(tempPeriod);
        }, _SECONDS_IN_MS)
      );
    }
  }, [TACcountdownPeriod]);

  useEffect(() => {
    if (!isTacModal) {
      clearTimeout(timerPeriod);
      clearTimeout(TACtimer);
    }
  }, [isTacModal]);

  /*const checkMemberEmail = async () => {
    let formData = {
      username: username,
    };
    let formBody = createMultiPartFormBody(formData);
    let res = await ApiEngine.post(ApiUrl._API_CHECK_MEMBER_EMAIL, formBody);
    setEmailExist(res.success);
  };*/
  const submitLoginForm = async (data, e) => {
    // _dispatch(setBusy());
    setIsLoginLoading(true);
    // console.log(data);

    // setLoginAttemptUsername(data.username);
    if (data.isKeepedPassword) {
      ls.set("gR97lPRIn797", {
        Do3SwI4cuvqR: data.username,
        gR1IPGBN01dS: data.password,
        PYTLczcty9uH: data.isKeepedPassword,
      });
    } else {
      ls.set("gR97lPRIn797", {
        Do3SwI4cuvqR: "fake",
        gR1IPGBN01dS: "null",
        PYTLczcty9uH: false,
      });
    }
    let loginRes = await _dispatch(
      performLoginReturnMessage(
        data.username,
        data.password,
        activeLanguage
      )
    );

    let { type: loginType, message } = loginRes;

    let params = {
      username: data.username,
    };

    if (loginType == "LoginSuccess") {
      setVisibleLoginAlert(false);
      // setShowModalLogin(false);
      _dispatch(setLoginModal(false))
    } else {
      setVisibleLoginAlert(true);
      setLoginMessage(message);

      setTimeout(() => {
        setVisibleLoginAlert(false);
      }, 5000);
    }
    // _dispatch(setIdle());
    setIsLoginLoading(false);

  };
  async function submitForgotPasswordForm(data) {
    // _dispatch(setBusy());
    setFPButtonAttempt(FPButtonAttempt + 1);
    setIsForgotPasswordLoading(true);
    let formData = {
      language: "en",
      username: data.username,
      type: verifyState.button,
      usage: "resetPassword",
    };
    let formBody = createMultiPartFormBody(formData);
    var responseJson = await ApiEngine.post(ApiUrl._API_GENERATE_OTP, formBody);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setCountdownTAC(false);
      setUsername(data.username);
      setPhoneNumber(responseJson.data);
      setTempPhoneNum(
        responseJson.data.replace(
          responseJson.data.substr(3, responseJson.data.length - 7),
          responseJson.data
            .substr(1, responseJson.data.length - 7)
            .replace(/./g, "*")
        )
      );
      setIsTacModal(true);
      setVerifyPhoneTAC(true);
      setIsForgetPassword(false);
      setCanSendTAC(false);
      clearTimeout(TACtimer);
      clearTimeout(timerPeriod);
      setTACCountdownPeriod(_TAC_VALID_TIMER);
      setTACsent(true);
      setTACtimedOut(false);
      setCountdownPeriod(_TAC_VALID_TIMER);

      // if return object is email then directly show CS (CN player)
      let regEmail =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (regEmail.test(responseJson.data)) {
        setAttempt(attempt + 1);
      }
      setTimeout(() => {
        setCanSendTAC(true);
        setCountdownTAC(true);
      }, _TAC_VALID_TIMER * _SECONDS_IN_MS);
    } else {
      _dispatch(
        showMessage({
          custom: true,
          customIcon: (
            <img
              className="m-auto"
              src={alertIcon}
              height="120px"
              width="120px"
              alt="Custom Icon"
            />
          ),
          content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
        })
      );
      // _dispatch(
      // 	showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
      // );
    }
    // _dispatch(setIdle());
    setIsForgotPasswordLoading(false);
  }

  const handleChange = (event) => {
		// Remove spaces and convert to lowercase
		const newValue = event.target.value.replace(/\s/g, "").toLowerCase();
		_setEmail(newValue);
	};

  async function submitVerifyTAC(pin) {
    // _dispatch(setBusy());
    setIsTACLoading(true);
    if (verifyState.button == 1) {
      let formData = {
        taccode: pin,
        username: username,
      };
      let formBody = createMultiPartFormBody(formData);
      let TACjson = await ApiEngine.post(
        ApiUrl._API_VERIFY_RESET_PASSWORD_SMS,
        formBody
      );
      if (TACjson[ApiKey._API_SUCCESS_KEY]) {
        setTacCode(pin)
        setVerifyPhoneTAC(false);
        setIsUpdatePassword(true);
        //resetForm();

        console.log('elePin', elePin)
      } else {
        _dispatch(
          showMessage({
            custom: true,
            customIcon: (
              <img
                className="m-auto"
                src={alertIcon}
                height="120px"
                width="120px"
                alt="Custom Icon"
              />
            ),
            content: t(TACjson[ApiKey._API_MESSAGE_KEY]),
          })
        );
      }
    } else if (verifyState.button == 2) {
      let formData = {
        language: "en",
        username: username,
        type: verifyState.button,
        usage: "resetPassword",
      };
      let formBody = createMultiPartFormBody(formData);
      //data["phoneNumber"] = formattedPhoneNumber;
      // data["language"] = "en";
      var responseJson = await ApiEngine.post(
        ApiUrl._API_GENERATE_OTP,
        formBody
      );

      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        setCountdownTAC(false);
        setPhoneNumber(responseJson.data);
        setTempPhoneNum(
          responseJson.data.replace(
            responseJson.data.substr(3, responseJson.data.length - 7),
            responseJson.data
              .substr(1, responseJson.data.length - 7)
              .replace(/./g, "*")
          )
        );
        setVerifyPhoneTAC(true);
        setIsForgetPassword(false);
        setAttempt(attempt + 1);
        verifyState.button = verifyState.button + 1;
        setCanSendTAC(false);
        clearTimeout(TACtimer);
        setTACCountdownPeriod(_TAC_VALID_TIMER);
        setTACsent(true);
        setTACtimedOut(false);
        setCountdownPeriod(0);
        setCountdownPeriod(_TAC_VALID_TIMER);
        clearTimeout(timerPeriod);

        setTimeout(() => {
          setCanSendTAC(true);
          setCountdownTAC(true);
          setTACtimedOut(true);
        }, _TAC_VALID_TIMER * _SECONDS_IN_MS);

        //resetForm();
        elePin.current.clear();
      } else {
        _dispatch(
          showMessage({
            custom: true,
            customIcon: (
              <img
                className="m-auto"
                src={alertIcon}
                height="120px"
                width="120px"
                alt="Custom Icon"
              />
            ),
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
          })
        );
        // _dispatch(
        // 	showResponseMessage(
        // 		responseJson[ApiKey._API_SUCCESS_KEY],
        // 		responseJson[ApiKey._API_MESSAGE_KEY]
        // 	)
        // );
      }
    }
    // _dispatch(setIdle());
    setIsTACLoading(false);
  }

  async function onKeyPress(key, index) {
		//setAllowPinClick(false);
		// 1st: have to check currentPinRef
		// 2nd: have to know which index is currently focused for altering the state.value
		// 3rd: have to check if at elePin.index(3), if yes then convert value to string and focus on eleConfirm.index(0)
		// 4th: have to check if at eleConfirm.index(3), if yes then convert value to string, and submitPin
		// let currentIndex = 0;
		let pinStr = tempPinNumber;
		let keyLength = key.split("");
		if (key !== "{bksp}" && key !== "&nbsp;") {
			pinStr = key;
			setTempPinNumber(pinStr);
			if (keyLength.length !== 6) {
			} else {
				setTempPinNumber("");
				setPinInputDisabled(true);
				setPinLoading(true);

				await submitVerifyTAC(pinStr);

				setPinLoading(false);
				setPinInputDisabled(false);
			}
		} else {
			elePin["current"].clear();
			setTempPinNumber("");
		}
	}

  async function submitNewPassword(data) {
    // _dispatch(setBusy());
    setIsResetPasswordLoading(true);
    let formData = {
      newpassword: data.newPassword,
      confpassword: data.confPassword,
      username: username,
      tacCode: tacCode
    };

    let formBody = createMultiPartFormBody(formData);

    var responseJson = await ApiEngine.post(
      ApiUrl._API_MEMBER_RESET_PASSWORD_BEFORE_LOGIN,
      formBody
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setIsUpdatePassword(false);
      setIsPasswordUpdated(true);
    } else {
      _dispatch(
        showMessage({
          custom: true,
          customIcon: (
            <img
              className="m-auto"
              src={alertIcon}
              height="120px"
              width="120px"
              alt="Custom Icon"
            />
          ),
          content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
        })
      );
      //   _dispatch(
      //     showResponseMessage(
      //       responseJson[ApiKey._API_SUCCESS_KEY],
      //       responseJson[ApiKey._API_MESSAGE_KEY]
      //     )
      //   );
    }
    // _dispatch(setIdle());
    setIsResetPasswordLoading(false);
  }
  async function submitPasswordUpdated(data) {
    setIsUpdatePassword(false);
    setIsPasswordUpdated(false);
    // setIsLogin(true);
    setIsTacModal(false);
  }
  const preventZoom = (event) => {
    event.preventDefault();
  };

  async function getCompanyIcon() {
		_dispatch(setBusy());
		const logo = find(companyIcons, { iconCategory: "logo" });
		if (logo) {
			setLogo(logo.iconImage);
		}
    
    const alert = find(companyIcons, { iconCategory: "alert" });
		if (alert) {
			setAlertIcon(alert.iconImage);
		}

    const loginBanner = find(companyIcons, { iconCategory: "login_popup" });
		if (loginBanner) {
			setLoginBanner(loginBanner.iconImage);
		}
		_dispatch(setIdle());
	}

  return (
    <div>
      <Modal
        id="t3-login-register-modal"
        fade={false}
        contentClassName={"t3-login-register-modal"}
        isOpen={loginModal}
        toggle={() => {
          _dispatch(setLoginModal(false))
          // setShowModalLogin(false);
        }}
        backdrop={'static'}
        centered
        fullscreen
      >
        <ModalBody>
          <div className="t3-lr-modal-container">
            <div className="t3-lr-modal-header">
                <div className="t3-logo">
                  <img
                    src={logo}
                    className="img-responsive"
                    alt="logo"
                  />
                </div>
              <div
                className="t3-close-modal"
                onClick={() => {
                  // setShowModalLogin(!showModalLogin);
                  _dispatch(setLoginModal(!loginModal))

                  setForgotPassword(false);
                }}
              >
                <VIcon icon={themeIcons.close} className="vicon" />
              </div>
            </div>
            <div className="t3-lr-modal-body">
              <Alert
                className="fail-alert"
                color="info"
                isOpen={visibleLoginAlert}
              >
                {t(`${loginMessage}`)}
                <i className="fas fa-exclamation-circle"></i>
              </Alert>
              <div className="left">
                {/*<div className="text">*/}
                {/*  {t("BUILD_THE_BEST_CASINO_TOGETHER")}*/}
                {/*</div>*/}
                <div className="image">
                  <img
                    src={loginBanner}
                    className="img-responsive"
                    alt="BUILD THE BEST CASINO TOGETHER"
                  />
                </div>
              </div>
              <div className="right">
                {!forgotPassword && (
                  <form onSubmit={loginHandleSubmit(submitLoginForm)}>
                    <div className="t3-lr-form">
                      <div className="t3-input-container">
                        <label>{t("ENTER_USERNAME")}</label>
                        <div>
                          <Input
                            id="loginUsername"
                            className="t3-custom-input-text"
                            name="username"
                            ref={loginRegister({ required: true })}
                            autoFocus
														autoComplete="off"
                            errors={loginErrors}
                            placeholder={t("ENTER_USERNAME")}
                            onTouchStart={preventZoom}
                            // onKeyDown={
                            //   (e) => {
                            //     const key = e.key;
                            //     const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'];

                            //     if (!/[a-z0-9]/.test(key) && !allowedKeys.includes(key)) {
                            //       e.preventDefault();
                            //     }
                            //   }
                            // }
                            onKeyDown={(e) => {
                              const key = e.key;
                              const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'];
                              
                              // Allow lowercase letters, numbers, and allowed keys
                              if (!/[a-z0-9]/.test(key) && !allowedKeys.includes(key)) {
                                if (/[A-Z]/.test(key)) {
                                  e.preventDefault();
                                  const start = e.target.selectionStart;
                                  const end = e.target.selectionEnd;
                                  
                                  // Insert the lowercase version of the uppercase key
                                  e.target.value = e.target.value.slice(0, start) + key.toLowerCase() + e.target.value.slice(end);
                                  // Adjust the cursor position to be after the inserted character
                                  e.target.setSelectionRange(start + 1, start + 1);
                                } else {
                                  // Prevent any other unwanted key input
                                  e.preventDefault();
                                }
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="t3-input-container mt-3">
                        <label>{t("ENTER_PASSWORD")}</label>
                        <div className="position-relative">
                          <Input
                            id="loginPassword"
                            className="t3-custom-input-text"
                            name="password"
                            ref={loginRegister({ required: true })}
                            type={hideLoginPassword ? "password" : "text"}
                            errors={loginErrors}
                            placeholder={t("ENTER_PASSWORD")}
                          />
                          <VIcon
                            icon={
                              hideLoginPassword
                                ? themeIcons.eyeBlocked
                                : themeIcons.eye
                            }
                            className="vicon"
                            style={{ top: "20px" }}
                            onClick={() =>
                              setHideLoginPassword(!hideLoginPassword)
                            }
                          />
                        </div>
                      </div>
                      <div className="t3-lr-button-box mt-4">
                        <div>
                          <button
                            className="t3-custom-light-btn"
                            disabled={isLoginLoading}
                          >
                            {isLoginLoading ? (
                              <Spinner
                                className="dynamic-table-spinner"
                                animation="border"
                                role="status"
                                size="sm"
                                style={{
                                  verticalAlign: "middle",
                                  width: "1.5rem",
                                  height: "1.5rem",
                                  marginLeft: 3,
                                }}
                              ></Spinner>
                            ) : (
                              t("LOGIN")
                            )}
                          </button>
                        </div>
                        <div>
                          <button
                            className="t3-custom-gray-btn"
                            onClick={() => setForgotPassword(true)}
                            disabled={isLoginLoading}
                          >
                            {t("FORGOT_PASSWORD")}
                          </button>

                         
                        </div>
                        
                      </div>
                      <div className="t3-lr-button-box mt-3">
                        <span>{t("DO_NOT_HAVE_AN_ACCOUNT_YET")}</span>{" "}<b className="header-login-btn" onClick={() => { _dispatch(setLoginModal(false)); _dispatch(setRegisterModal(true)); }}>{t("REGISTER_NOW")}</b>
                      </div>
                    </div>
                  </form>
                )}
                {forgotPassword && (
                  <form
                    onSubmit={forgotPasswordHandleSubmit(
                      submitForgotPasswordForm
                    )}
                  >
                    <div className="t3-lr-form">
                      <div className="t3-form-title">
                        <div
                          className="t3-back-btn"
                          onClick={() => {
                            setForgotPassword(false);
                          }}
                        >
                          <VIcon icon={themeIcons.angleLeft} className="vicon" size={20} />
                        </div>
                        <div className="t3-title">{t("FORGOT_PASSWORD")}</div>
                      </div>
                      <div className="t3-input-container">
                        <label>{t("ENTER_USERNAME")}</label>
                        <div>
                          <Input
                            type="text"
                            name="username"
                            id="username"
                            className="t3-custom-input-text text-lowercase"
                            placeholder={t("USERNAME")}
                            autoComplete={"off"}
                            ref={forgotPasswordRegister({
                              required: "REQUIRED_USERNAME",
                            })}
                            errors={forgotPasswordErrors}
                          />
                        </div>
                      </div>
                      <div className="t3-lr-button-box mt-4">
                        <div className="d-flex flex-column">
                          <button className="t3-custom-light-btn">
                            {t("RESET_PASSWORD")}
                            {isForgotPasswordLoading ? (
                              <Spinner
                                className="dynamic-table-spinner"
                                animation="border"
                                role="status"
                                size="sm"
                                style={{
                                  verticalAlign: "middle",
                                  width: "0.875rem",
                                  height: "0.875rem",
                                  marginLeft: 3,
                                }}
                              ></Spinner>
                            ) : (
                              ""
                            )}
                          </button>
                          {FPButtonAttempt > 2 && (
                            <div className="t3-form-notice">
                              <span className="t3-form-notice-text" dangerouslySetInnerHTML={{ __html: t("FORGOT_PASSWORD_PROBLEM_NOTICE", { url: customerServiceUrl }) }}></span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        id="t3-login-register-modal"
        fade={false}
        contentClassName={"t3-login-register-modal"}
        isOpen={isTacModal}
        backdrop={'static'}
        toggle={() => {
          setIsTacModal(false);
          setIsForgetPassword(true);
          setVerifyPhoneTAC(false);
          setIsUpdatePassword(false);
          setIsPasswordUpdated(false);
          //   setIsLogin(true);
  
        }}
        centered
      >
        <ModalBody>
          <div className="t3-lr-modal-container">
            <div className="t3-lr-modal-header">
              <div className="t3-logo">
                <img
                  src={logo}
                  className="img-responsive"
                  alt="logo"
                />
              </div>
              <div
                className="t3-close-modal"
                onClick={() => {
                  setIsTacModal(!isTacModal)
                }}
              >
                <VIcon icon={themeIcons.close} className="vicon" />
              </div>
            </div>
            <div className="t3-lr-modal-body">
              <div className="left">
                {/*<div className="text">*/}
                {/*  {t("BUILD_THE_BEST_CASINO_TOGETHER")}*/}
                {/*</div>*/}
                <div className="image">
                  <img
                    src={loginBanner}
                    className="img-responsive"
                    alt="BUILD THE BEST CASINO TOGETHER"
                  />
                </div>
              </div>
              <div className="right">
                {verifyPhoneTAC && (
                  <>
                    <div className="t3-lr-pin-box">
                      <h3 className="t3-pin-title mb-0">
                        {t("RESET_PASSWORD")}
                      </h3>
                      <div className="t3-pin-title2 mb-3">
                        {t("ENTER_THE_CODE_WE_SENT_TO")}{" "}
                        {_emailText != "" ? _emailText : tempPhoneNum}.
                      </div>
                      <PinInput
                        length={6}
                        // initialValue="------"
                        ref={elePin}
                        // secret
                        type="numeric"
                        disabled={pinInputDisabled}
                        inputMode="numeric"
                        inputStyle={{ border: "0", margin: "0px 5px" }}
                        regexCriteria={/^[ 0-9_@./#&+-]*$/}
                        onComplete={(value, index) => {
                          onKeyPress(value, index);
                        }}
                      />
                      {TACsent && countdownPeriod != 0 && (
                        <p className="tac-countdown">
                          {t("TAC_SENT")}{" "}{/* {t("RESEND_IN")} */}{countdownPeriod}s
                        </p>
                      )}

                      {pinLoading ? (
                        <div className="mt-2">
                          <Spinner
                            className="dynamic-table-spinner"
                            animation="border"
                            role="status"
                            size="sm"
                            style={{
                              verticalAlign: "middle",
                              width: "1.5rem",
                              height: "1.5rem",
                              marginLeft: 3,
                            }}
                          ></Spinner>
                        </div>
                      ) : (
                        ""
                      )}

                      {countdownPeriod == 0 && (
                        <div>
                          <button
                            className="tac-countdown"
                            style={{
                              background: "none",
                              border: "none",
                              padding: 0,
                              cursor: "pointer",
                            }}
                          >
                            {t("DIDNT_RECEIVE_A_CODE")}
                            <br />
                            <span dangerouslySetInnerHTML={{ __html: t("TRY_CONTACTING_OUR_CUSTOMER_SERVICE", { url: customerServiceUrl }) }} />
                          </button>
                        </div>

                        // TODO: Change forgot password system to allow resend OTP once after 60s.
                        // {attemp == 1 && (
                        //   <div>
                        //     <button
                        //       className="t3-custom-light-btn m-t-30"
                        //       onClick={() => {
                        //         setSendOTPViaEmail(true);
                        //       }}
                        //       disabled={pinInputDisabled}
                        //     >
                        //       {t("RECEIVE_OTP_VIA_EMAIL")}
                        //     </button>
                        //   </div>
                        // )}
                        // {attemp == 2 && (
                        //   <div>
                        //     <button
                        //       className="t3-custom-light-btn"
                        //       onClick={() => setIsLiveChatOpen(true)}
                        //       disabled={pinInputDisabled}
                        //     >
                        //       {t("CONTACT_CUSTOMER_SERVICE")}
                        //     </button>
                        //   </div>
                        // )}
                      )}
                    </div>
									</>
                )}
                {isUpdatePassword && (
                  <form onSubmit={ResetPasswordHandleSubmit(submitNewPassword)}>
                    <div className="t3-lr-form">
                      <div className="t3-form-title">
                        <div className="t3-title">{t("RESET_PASSWORD")}</div>
                      </div>
                      <div className="t3-input-container">
                        <label>{t("ENTER_NEW_PASSWORD")}</label>
                        <Input
                          type="password"
                          name="newPassword"
                          id="newPassword"
                          className="t3-custom-input-text"
                          placeholder={t("NEW_PASSWORD")}
                          autoComplete="off"
                          ref={ResetPasswordRegister({
                            required: true,
                            minLength: {
                              value: _MIN_PASSWORD_LENGTH,
                              message: t("MIN_CHARACTERS", {
                                length: _MIN_PASSWORD_LENGTH,
                              }),
                            },
                            validate: (value) =>
                              watch("confPassword") == "" ||
                              value != watch("confPassword") ||
                              "PASSWORD_MISMATCH",
                          })}
                          errors={ResetPasswordErrors}
                        />
                      </div>
                      <div className="t3-input-container">
                        <label>{t("ENTER_CONFIRM_PASSWORD")}</label>
                        <Input
                          type="password"
                          name="confPassword"
                          id="confPassword"
                          className="t3-custom-input-text"
                          placeholder={t("CONFIRM_NEW_PASSWORD")}
                          autoComplete="off"
                          ref={ResetPasswordRegister({
                            required: true,
                            minLength: {
                              value: _MIN_PASSWORD_LENGTH,
                              message: t("MIN_CHARACTERS", {
                                length: _MIN_PASSWORD_LENGTH,
                              }),
                            },
                            validate: (value) =>
                              watch("newPassword") == "" ||
                              value != watch("newPassword") ||
                              "PASSWORD_MISMATCH",
                          })}
                          errors={ResetPasswordErrors}
                        />
                      </div>
                      <div className="t3-lr-button-box mt-4">
                        <div>
                          <button className="t3-custom-light-btn">
                            {isResetPasswordLoading ? (
                              <Spinner
                                className="dynamic-table-spinner"
                                animation="border"
                                role="status"
                                size="sm"
                                style={{
                                  verticalAlign: "middle",
                                  width: "1.5rem",
                                  height: "1.5rem",
                                  marginLeft: 3,
                                }}
                              ></Spinner>
                            ) : (
                              t("RESET_PASSWORD")
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
                {isPasswordUpdated && (
                  <form
                    onSubmit={PasswordUpdatedHandleSubmit(
                      submitPasswordUpdated
                    )}
                  >
                    <div className="t3-lr-success">
                      <h4 className="t3-primary-text-color">
                        {t("RESET_PASSWORD_SUCCESS")}
                      </h4>
                      <div className="t3-lr-button-box mt-3">
                        <div
                          onClick={() => {
                            setIsTacModal(!isTacModal);
                            setForgotPassword(false);
                            // setShowModalLogin(true);
                            _dispatch(setLoginModal(true));
                          }}
                        >
                          <button className="t3-custom-light-btn">
                            {t("GO_TO_LOGIN")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default LoginModal;
