import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import DateTime from 'react-datetime';
import moment from 'moment';
import classNames from 'classnames';
import { useSelector } from "react-redux";

const DateRangeFilter = (props) => {
    let _history = useHistory();
    const { t, i18n } = useTranslation();

    const [sDate, setSDate] = useState();
    const [eDate, setEDate] = useState();
    const [selectedOption, setSelectedOption] = useState({});
    const [openCalendar, setOpenCalendar] = useState(false);
    const [optionClicked, setOptionClicked] = useState(false);
    const [currMinDate, setCurrMinDate] = useState(moment());
    const [currMaxDate, setCurrMaxDate] = useState(moment());
    const [chgOption, setchgOption] = useState();
    var _dateRef = useRef(null);

    const {
        startDate = sDate,
        endDate = eDate,
        setStartDate,
        setEndDate,
        minDate,
        maxDate,
        onStartDateChange,
        onEndDateChange,
        onSave,
        options = false,
        ...rest
    } = props;
    const _PREV_DAY = "PREV";
    const _NEXT_DAY = "NEXT";

    const _DATE_SELECTIONS = [
        // {
        //     label: t("PREV_DAY"),
        //     from: _PREV_DAY,
        // },
        {
            label: t("TODAY"),
            from: moment().startOf('day'),
            to:moment().endOf('day'),
            //initialType: moment().startOf('day').format("DD-MM-YYYY") + t("TO") + moment().endOf('day').format("DD-MM-YYYY")
        },
        {
            label: t("YESTERDAY"),
            from: moment().subtract(1, 'day').startOf('day'),
            to: moment().subtract(1, 'day').endOf('day'),
            initialType: moment().subtract(1, 'day').startOf('day').format("DD-MM-YYYY") + t("TO") + moment().subtract(1, 'day').endOf('day').format("DD-MM-YYYY")
        },
        {
            label: t("THIS_WEEK"),
            from: moment().startOf('isoWeek'),
            to:moment().endOf('day'),
            initialType: moment().startOf("isoWeek").format("DD-MM-YYYY") + t("TO") + moment().endOf('day').format("DD-MM-YYYY")
        },
        {
            label: t("LAST_WEEK"),
            from: moment().subtract(1, 'week').startOf('isoWeek'),
            to: moment().subtract(1, 'week').endOf('isoWeek'),
            initialType: moment().subtract(1, "week").startOf("isoWeek").format("DD-MM-YYYY") + t("TO") + moment().subtract(1, "week").endOf("isoWeek").format("DD-MM-YYYY")
        },
        {
            label: t("THIS_MONTH"),
            from: moment().startOf('month'),
            to:moment().endOf('day'),
            initialType: moment().startOf("month").format("DD-MM-YYYY") + t("TO") + moment().endOf('day').format("DD-MM-YYYY")
        },
        {
            label: t("LAST_MONTH"),
            from: moment().subtract(1, 'month').startOf('month'),
            to: moment().subtract(1, 'month').endOf('month'),
            initialType: moment().subtract(1, "month").startOf("month").format("DD-MM-YYYY") + t("TO") + moment().subtract(1, 'month').endOf('month').format("DD-MM-YYYY")
        },
        // {
        //     label: t("NEXT_DAY"),
        //     from: _NEXT_DAY
        // },
    ];

    useEffect(() => {

        let tempMinDate = props.minDate;
        let tempMaxDate = props.maxDate;
        if (props.minDate === undefined) {
            tempMinDate = moment().subtract(2, 'months');
        }
        if (props.maxDate === undefined) {
            tempMaxDate = moment();
        }
        setCurrMinDate(tempMinDate);
        setCurrMaxDate(tempMaxDate);
        let startDateMoment;
        let endDateMoment;
        if (selectedOption.from == _PREV_DAY) {
            startDateMoment = moment(startDate).subtract(1, 'day');
            endDateMoment = moment(startDate).subtract(1, 'day');

            if(moment(startDate).startOf('day').isSameOrBefore(moment(currMinDate).add(1,'day').startOf('day'))){
                startDateMoment = moment(startDate);
                endDateMoment = moment(startDate);
            }
        } else if (selectedOption.from == _NEXT_DAY) {
            startDateMoment = moment(startDate).add(1, 'day');
            endDateMoment = moment(startDate).add(1, 'day');   
            
            if(moment(startDate).startOf('day').isSameOrAfter(moment(currMaxDate).startOf('day'))){
                startDateMoment = moment(startDate);
                endDateMoment = moment(startDate)
            }
        } else {
            startDateMoment = selectedOption.from ? selectedOption.from : moment();
            endDateMoment = selectedOption.to ? selectedOption.to : moment();
        }

        if (startDate === undefined || optionClicked) {
            setSDate(startDateMoment);
            if (typeof (setStartDate) !== "undefined") {
                props.setStartDate(startDateMoment);
            }
        }
        else
            setSDate(startDate);
        if (endDate === undefined || optionClicked) {
            setEDate(endDateMoment);
            if (typeof (setEndDate) !== "undefined") {
                props.setEndDate(endDateMoment);
            }
        }
        else
            setEDate(endDate);

        setOptionClicked(false);
    }, [selectedOption]);

    useEffect(() => {
        if (openCalendar) {
            _dateRef.current.openCalendar();
        }
    }, [openCalendar])

    function handleStartDate(date) {
        date=moment(date).startOf('day');
        if(!checkOptionActive(date))setchgOption(true);
        setSDate(date);
        props.setStartDate(date);
        setOpenCalendar(true);
        if (onStartDateChange) {
            onStartDateChange(date);
        }
    }

    useEffect(() => {
        if (chgOption) {
          var object={};
          object.initialType="";
          setOptionClicked(false);
          setSelectedOption(object.initialType);
          updateTypeOnSelection(object);
          setchgOption(false);
        }
      }, [chgOption]);

    function handleEndDate(date) {
        date=moment(date).endOf('day');
        if(!checkOptionActive(date))setchgOption(true);      
        setEDate(date);
        props.setEndDate(date);
        if (onEndDateChange) {
            onEndDateChange(date);
        }
    }

    function checkOptionActive(date){
        var isActive=false;

        if(props.initialType !== undefined && props.initialType.trim()!==''){
        const [startDateStr, endDateStr] = props.initialType.split(" To ");

        var startDate=moment(new Date(startDateStr.split('-').reverse().join('-'))).startOf('day');
        var endDate=moment(new Date(endDateStr.split('-').reverse().join('-'))).startOf('day');

        if(
        moment(date).startOf('day').isBetween(startDate, endDate, undefined, '[]')
        ){
           isActive=true;  
        }

    }

        return isActive;
    }

    function handleSave() {
        if (onSave) {
            onSave(sDate, eDate);
        }
    }
    var sDateValid = function(current){
        return current.isSameOrAfter(currMinDate) && current.isSameOrBefore(moment(eDate).startOf('day'));
    }

    function updateTypeOnSelection(selection) {
        if (props.typeOnSelect != undefined) {
            props.typeOnSelect(selection.initialType);
        }
    }
    var eDateValid = function(current){
        return current.isSameOrAfter(moment(sDate).startOf('day')) && current.isSameOrBefore(currMaxDate);
    }
    return (
        /*<div id={activeTheme === 0 ? "date-range-filter" : "date-range-filter-modern"}>*/
        <div className="date-selection">
        <div className="date-range-picker">
        <div className="date-selection-filter margin-bottom-0 mb-3">
                <div className="t3-input-container">
                    <label className="">
                        <b>{t("START_DATE")}</b>
                    </label>
                    <DateTime
                        inputProps={{ className: "t3-custom-input-text date", readOnly: true }}
                        name="startDate"
                        dateFormat="DD-MM-YYYY"
                        closeOnSelect={true}
                        value={moment(sDate).format("DD-MM-YYYY")}
                        isValidDate={sDateValid}
                        timeFormat={false}
                        onChange={handleStartDate}
                    />
                </div>

                <div className="t3-input-container ldtPicker">
                    <label className="">
                        <b>{t("END_DATE")}</b>
                    </label>
                    <DateTime
                        inputProps={{ className: "t3-custom-input-text date", readOnly: true }}
                        name="endDate"
                        dateFormat="DD-MM-YYYY"
                        closeOnSelect={true}
                        timeFormat={false}
                        value={moment(eDate).format("DD-MM-YYYY")}
                        isValidDate={eDateValid}
                        ref={_dateRef}
                        onChange={handleEndDate}
                    />
                </div>

                
            </div>
            <ul className="list-unstyled add-remove-week-filter">
                {_DATE_SELECTIONS.map((selection, index) => {
                    return (
                        <li
                            key={index}
                            className={
                                selectedOption.label === selection.label || 
                                (props.initialType !== undefined && selection.initialType === props.initialType)
                                    ? "active"
                                    : null
                            }
                            onClick={() => {
                                setOptionClicked(true);
                                setSelectedOption(selection);
                                updateTypeOnSelection(selection);                     
                            }}
                        >
                            <div style={{textTransform: "capitalize"}}>{selection.label}</div>
                        </li>
                    );
                })}
            </ul>
            
        
        </div>

    </div>
    );
};

export default DateRangeFilter;