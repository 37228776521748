import { themeIcons } from "config/theme";
import Deposit from "pages/template_1/transaction/Deposit";
import WithdrawalMethods from "pages/template_1/transaction/WithdrawalMethods";
import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import i18n from "localization/i18n";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import VIcon from "components/custom/Icon";
import { useDispatch, useSelector } from "react-redux";
import { setDepositModal, setDepositTab } from "redux/AppAction";
import { ApiKey, ApiUrl } from "../../util/Constant";
import ApiEngine from "../../util/ApiEngine.js";
import { showResponseMessage } from "../../redux/AppAction.js";
import { numberWithCurrencyFormat } from "../../util/Util";
import { map } from "lodash";
import SweetAlert from "react-bootstrap-sweetalert";

const DepositWithdrawalModal = (props) => {
	var { t } = useTranslation();
	const isDepositModal = useSelector((state) => state.appState.depositModal);
	const depositAndWithdrawActive = useSelector(
		(state) => state.appState.depositTab
	);
	var _dispatch = useDispatch();
	const [rolloverWarning, setRolloverWarning] = useState(false);
	const [rolloverData, setRolloverData] = useState([]);

	async function checkMemberRollover() {
		try {
			getLatestRolloverRecord();
			var rolloverJson = await ApiEngine.get(ApiUrl._API_CHECK_MEMBER_ROLLOVER);
			if (rolloverJson[ApiKey._API_SUCCESS_KEY]) {
				_dispatch(setDepositModal(true, 2));
			} else {
				setRolloverWarning(true);
			}
		} catch (err) {
			_dispatch(showResponseMessage(false, err));
		}
	}

	async function getLatestRolloverRecord() {
		var responseJson = await ApiEngine.get(
			ApiUrl._API_GET_LATEST_ROLLOVER_RECORD
		);

		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			setRolloverData(responseJson[ApiKey._API_DATA_KEY]);
		}
	}
	const closeBtn = (
		<div
			className="close"
			onClick={() => _dispatch(setDepositModal(!isDepositModal))}
		>
			<VIcon icon={themeIcons.close} className="vicon" />
		</div>
	  );

	return (
		<Modal
			id="t3-custom-modal"
			fade={false}
			isOpen={isDepositModal}
			className="modal-custom-fullscreen"
			contentClassName={"t3-custom-modal"}
			backdrop={"static"}
			toggle={() => {
				// setShowModal(false);
				_dispatch(setDepositModal(!isDepositModal));
			}}
			fullscreen
			//   centered
		>
			{/* <ModalHeader toggle={() => {
				// setShowModal(false);
				_dispatch(setDepositModal(!isDepositModal));
			}}>Modal title</ModalHeader> */}
			<ModalHeader close={closeBtn}>	
				{depositAndWithdrawActive == 1 ? t("DEPOSIT") : t("WITHDRAWAL")}
			</ModalHeader>
			<ModalBody>
				{rolloverWarning && (
					<div className="sweet-alert-container t3-sweet-alert-container">
						<SweetAlert
							danger
							confirmBtnText={t("OK")}
							onConfirm={() => {
								setRolloverWarning(false);
							}}
						>
							<span className="sweet-alert-custom-text">
								{t("ROLLOVER_REQUIREMENT_ERROR")}
							</span>

							<div className="t3-rollover-sweet-alert mt-3">
								<div className="t3-rollover-sweet-alert-header">
									<div className="first">{t("LATEST_ROLLOVER_PROGRESS")}</div>
									<div className="second t3-rollover-box mt-3">
										{rolloverData &&
											rolloverData.length > 0 &&
											map(rolloverData, (item, index) => {
												const percentage =
													(item["isLatestRecord"] == 1
														? item["rolloverProgress"]
														: item["rolloverTarget"]) == 0 &&
													item["rolloverTarget"] == 0
														? "0%"
														: ((item["isLatestRecord"] == 1
																? item["rolloverProgress"]
																: item["rolloverTarget"]) /
																item["rolloverTarget"]) *
														  100;
												return (
													<div className="t3-rollover-sweet-alert-info">
														<div className="t3-progress green">
															<span className="t3-progress-left">
																<span
																	className="t3-progress-bar"
																	style={{
																		transform:
																			percentage >= 50
																				? `rotate(180deg)`
																				: `rotate(${
																						(percentage / 100) * 180
																				  }deg)`,
																	}}
																></span>
															</span>
															<span className="t3-progress-right">
																<span
																	className="t3-progress-bar"
																	style={{
																		transform:
																			percentage > 50
																				? `rotate(${
																						(34 / 100) * -180
																				  }deg)`
																				: `rotate(-0deg)`,
																	}}
																></span>
															</span>
															<div className="t3-progress-value">
																{percentage.toFixed(2)}
																<span>%</span>
															</div>
														</div>

														<div className="t3-rollover-sweet-alert-body">
															<span className="title">
																{/* {t("LATEST_TOP_UP")} :{" "} */}
																{t("LATEST_TOP_UP_AND_BONUS")} :{" "}
															</span>
															<span className="amount">
																{numberWithCurrencyFormat(
																	parseFloat(item["amount"]),
																	2,
																	true
																)}
															</span>
															<div className="date">{item["date"]}</div>
															<div className="highlight-info">
																{t("YOU_HAVE")}{" "}
																<b>
																	{item["isLatestRecord"] == 1
																		? numberWithCurrencyFormat(item["rolloverProgress"])
																		: numberWithCurrencyFormat(item["rolloverTarget"])}{" "}
																	/ {numberWithCurrencyFormat(item["rolloverTarget"])}
																</b>{" "}
																{t("TO_GO")}!
															</div>
														</div>
													</div>
												);
											})}
									</div>
								</div>
							</div>

							<div className="mt-3 mb-3 reminder-red">
								<div className="first">
									<div className="first">
										<img
											src={require("../../assets/img/new_icons/red.png")}
											className="img-responsive"
											alt="icon"
										/>
									</div>
									<div>
										<p className="text-left font14 mb-0 f-weight-500">
											{t("ROLLOVER_RED_WARNING")}
										</p>
									</div>
								</div>
							</div>
						</SweetAlert>
					</div>
				)}
				<div className="t3-custom-modal-container">
					<div className="custom-body">
						<div className="t3-two-custom-tabs hide-767">
							<div
								className={
									"item " + (depositAndWithdrawActive == 1 ? "active" : "")
								}
								onClick={() => {
									_dispatch(setDepositTab(1));
								}}
							>
								<div>{t("DEPOSIT")}</div>
								<VIcon icon={themeIcons.angleRight} className="vicon" />
							</div>
							<div
								className={
									"item " + (depositAndWithdrawActive == 2 ? "active" : "")
								}
								onClick={() => {
									checkMemberRollover();
								}}
							>
								<div>{t("WITHDRAWAL")}</div>
								<VIcon icon={themeIcons.angleRight} className="vicon" />
							</div>
						</div>
						{depositAndWithdrawActive == 1 && <Deposit />}
						{depositAndWithdrawActive == 2 && <WithdrawalMethods />}
					</div>
				</div>

				{/* <div className="t3-custom-modal-withdraw-container t3-modal-bg-dark">
					<div className="t3-cms-header">
						<div className="first">
							<div>
								{depositAndWithdrawActive == 1 ? t("DEPOSIT") : t("WITHDRAWAL")}
							</div>
						</div>
						<div className="second">
							<div
								className="t3-close-custom-modal"
								onClick={() => {
									_dispatch(setDepositModal(!isDepositModal));
								}}
							>
								<VIcon icon={themeIcons.close} className="vicon" />
							</div>
						</div>
					</div>
					<div className="t3-two-custom-tabs hide-767">
						<div
							className={
								"item " + (depositAndWithdrawActive == 1 ? "active" : "")
							}
							onClick={() => {
								_dispatch(setDepositTab(1));
							}}
						>
							<div>{t("DEPOSIT")}</div>
							<VIcon icon={themeIcons.angleRight} className="vicon" />
						</div>
						<div
							className={
								"item " + (depositAndWithdrawActive == 2 ? "active" : "")
							}
							onClick={() => {
								checkMemberRollover();
							}}
						>
							<div>{t("WITHDRAWAL")}</div>
							<VIcon icon={themeIcons.angleRight} className="vicon" />
						</div>
					</div>
					{depositAndWithdrawActive == 1 && <Deposit />}
					{depositAndWithdrawActive == 2 && <WithdrawalMethods />}
				</div> */}
			</ModalBody>
		</Modal>
	);
};

export default DepositWithdrawalModal;
